.gallery-container {
    img {
        // border-radius: 5px;
        cursor: pointer;
    }

    .pswp__img {
        width: 100%;
        cursor: auto;
    }

    .gallery-img {
        height: auto;
        width: 100%;
    }
    .gallery-img-detail {
        height: auto;
        width: '70px';
    }
}