.card {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    width: 100%;

    box-shadow: 16px 16px 26px 0 rgba(212, 218, 230, 0.7);
    -webkit-box-shadow: 16px 16px 26px 0 rgba(212, 218, 230, 0.7);
    -moz-box-shadow: 16px 16px 26px 0 rgba(212, 218, 230, 0.7);

    &.hero {
        box-shadow: 16px 16px 26px 0 rgba(212, 218, 230, 0.7) !important;
        -webkit-box-shadow: 16px 16px 26px 0 rgba(212, 218, 230, 0.7) !important;
        -moz-box-shadow: 16px 16px 26px 0 rgba(212, 218, 230, 0.7) !important;
    }

    @media screen and (max-width: 1200px) {
        margin: 10px 0;
    }

    transition: all 0.3s ease-in-out;

    &:hover:not(.no-hover) {
        box-shadow:16px 16px 30px 0 rgba(212, 218, 230, 0.9);
        -webkit-box-shadow:16px 16px 30px 0 rgba(212, 218, 230, 0.9);
        -moz-box-shadow:16px 16px 30px 0 rgba(212, 218, 230, 0.9);
        background-color: rgba(170, 210, 209, 0.45);
        filter: brightness(1.05);

        transform: scale(1.04);
        z-index: 100;
    }
     
    &.padded { 
        padding: 20px;
    }

    > div:last-child {
        margin-top: 10px;
        text-align: end;
    }

    iframe {
        width: 100%;
        // border-radius: 5px;
    }

    i {
        color: #69c2c0;
        margin-right: 10px;
        height: 32px;
        width: 32px;
        font-size: 32px;
    }

    &.review-card,
    &.article-card,
    &.video-gallery-card,
    &.search-result { 
        background-color: rgba(170, 210, 209, 0.1);
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        margin: 10px 0 0 0;

        &:hover:not(.no-hover) { 
            transform: scale(1.01);
            background-color: rgba(170, 210, 209, 0.25);
        }
    }
    &.article-card-featured {
        background-color:  rgba(105, 194, 192, 0.3);
        padding: 20px 0;

        &:hover:not(.no-hover) { 
            transform: scale(1.01);
            background-color: rgba(105, 194, 192, 0.35);
        }

        p {
            font-size: 18px;
        }
    }

    &.article-card {
        margin-top: 15px;
        margin-bottom: 0;
    }

    &.video-card:hover {
        transform: scale(1.01);
    }

    &.review-card{
        margin: 30px 0 !important;
    }
    &.search-result{
        margin: 20px 0 !important;
    }
}