body {
  background-color: #F8F9F9 !important;
  /* TODO: scrollbar */
}
body a {
  text-decoration: none;
  color: inherit;
}
body a:hover {
  text-decoration: none;
  color: inherit;
}

body .cert-wrapper {
  background-color: #171717;
}

body .certif {
  /* justify-content: center; */
  padding: 0 24px 50px 24px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 1200px) {
  body .certif {
    max-width: 1200px;
  }
}
@media (min-width: 600px) {
  body .certif {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (max-width: 899.95px) {
  body .certif {
    display: flex;
    justify-content: center;
  }
}

#multiple-checkbox svg {
  margin-right: -5px;
}

img {
  border-radius: 4px !important;
}