.social-links {
    display: flex;
    flex-direction: row !important;

    * {
        transition: all 0.3s ease-in-out;
    }

    a { 
        text-decoration: none;
    }

    .social-link-container {
        border-radius: 50%;
        border: 1px solid white;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 20px;

        &.fb:hover {
            border-color: #0094ff;
            i {
                color: #0094ff;
            }
        }
        &.yt:hover {
            border-color: #e83535;
            i {
                color: #e83535;
            }
        }
        &.insta:hover {
            border-color: #f568d7;
            i {
                color: #f568d7;
            }
        }
        &.twitter:hover {
            border-color: #00d4ff;
            i {
                color: #00d4ff;
            }
        }

        .fab {
            color: white;
            font-size: 20px;
        }
    }
}